<template>
    <v-btn-toggle v-model="buttons" multiple>
        <template v-for="(item, index) in items">
            <v-btn width="44" class="pa-0" :key="index" @click="emit(item)">
                <div class="d-flex flex-column">
                    <v-avatar size="30" class="mx-auto" :style="`background: ${item.value?.color};`" :class="item?.name === 'White' ? 'border' : ''">
                        <v-icon class="font-size-20" :color="item?.name === 'White' ? 'black' : 'white'">mdi-check</v-icon>
                    </v-avatar>
                    <div class="mt-8px font-size-12">
                        <div class="text-truncate">
                            {{ item.name }}
                        </div>
                        <div class="grey--text text--lighten-2">({{ item?.count || 0 }})</div>
                    </div>
                </div>
            </v-btn>
        </template>
    </v-btn-toggle>
</template>

<script>
export default {
    props: {
        filtering: { type: Object, default: () => ({}) },
    },
    data: () => ({
        items: [],
        buttons: [],
    }),
    computed: {
        query() {
            let { ...query } = this.$route.query;
            return query;
        },
    },
    mounted() {
        this.setItems();
        this.sync();
    },
    methods: {
        setItems() {
            let { populateValues = [], values } = this.filtering;
            this.items = populateValues.map(({ code, name, value, _id }) => {
                if(code === "Multicoloured") value.color = "linear-gradient(to right, rgb(208, 104, 2), rgb(248, 231, 28) 36%, rgb(114, 202, 13) 63%, rgb(0, 198, 255));"
                return { _id, name, value };
            });

            values.forEach(({ value, count }) => {
                let idx = this.items.findIndex((item) => item._id === value);
                this.items[idx].count = count;
            });
        },
        emit(item) {
            let { _id } = item;
            let colors = this.query?.color ? this.query.color.split("|") : [];

            if (colors.includes(_id)) {
                this.query.color = colors.filter((color) => color !== _id).join("|");
                if (!this.query.color) delete this.query.color;
            } else {
                this.query.color = [...colors, _id].join("|");
            }

            this.$router.push({ query: { ...this.query } });
        },
        sync() {
            let { color = "" } = this.query;
            this.buttons = color.split("|").map((_color) => this.items.findIndex(({ _id }) => _color === _id));
        },
    },
    watch: {
        query() {
            this.sync();
        },
        filtering() {
            this.setItems();
            this.sync();
        }
    },
};
</script>

<style lang="scss" scoped>
.v-btn-toggle {
    margin: -6px;
    flex-wrap: wrap;
    justify-content: center;
    .v-btn {
        height: auto !important;
        border: none !important;
        background-color: transparent !important;
        min-width: 0 !important;
        opacity: 1;
        margin: 6px;
        .v-avatar {
            position: relative;
            overflow: visible;
            .v-icon {
                opacity: 0;
            }
        }
        &::before {
            display: none;
        }

        &--active {
            .v-avatar {
                .v-icon {
                    opacity: 1;
                }
            }
        }
    }
}
</style>